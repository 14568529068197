const SET_FILES="SET_FILES"
const EMPTY_FILES="EMPTY_FILES"
const SET_CURRENT_DIR="SET_CURRENT_DIR"
const ADD_FILE="ADD_FILE"
const SET_POPUP_DISPLAY="SET_POPUP_DISPLAY"
const PUSH_TO_STACK="PUSH_TO_STACK"
const POP_STACK="POP_STACK"
const DELETE_FILE="DELETE_FILE"
const SEND_FILE="SEND_FILE"
const ACCEPT_FILE="ACCEPT_FILE"
const REJECT_FILE="REJECT_FILE"
const FILE_USERNAME="FILE_USERNAME"
const SET_VIEW="SET_VIEW"
const SET_FILESCOUNT="SET_FILESCOUNT"

const defaultState ={
    files: [],
    totalCount: 10,
    currentDir: null,
    popupDisplay: 'none',
    dirStack: [],
    view: 'list'
}

export default function fileReducer(state = defaultState, action){
    switch(action.type){
        case SET_FILES: 
            return {...state, files: action.payload}        
        case EMPTY_FILES: 
            return {...state, files: []}        
        case SET_CURRENT_DIR: 
            return {...state, currentDir: action.payload}
        case ADD_FILE: 
            return {...state, files: [...state.files, action.payload]}
        case SET_POPUP_DISPLAY: 
            return {...state, popupDisplay: action.payload}
        case PUSH_TO_STACK: 
            return {...state, dirStack: [...state.dirStack, action.payload]}                                
        case POP_STACK:            
            return {...state, currentDir: state.dirStack[state.dirStack.length-1], dirStack: state.dirStack.slice(0, state.dirStack.length-1)}
        case DELETE_FILE:            
            return {...state, files: [...state.files.filter(file => file._id !== action.payload)]}
        case SEND_FILE:  // Изменяем статус файла на SENT (отправлен / на проверке) после нажатия кнопки SEND
            return {...state, files: [...state.files.map(file => (
                file._id === action.payload
                  ? { ...file, status: 'SENT' }
                  : file
            ))]};
        case ACCEPT_FILE:  // Изменяем статус файла на ACCEPTED (принят) после нажатия кнопки "Принять"
            return {...state, files: [...state.files.map(file => (
                file._id === action.payload
                  ? { ...file, status: 'ACCEPTED' }
                  : file
            ))]};
        case REJECT_FILE:  // Изменяем статус файла на REJECTTED (отклонён) после нажатия кнопки "Отклонить"
            return {...state, files: [...state.files.map(file => (
                file._id === action.payload
                  ? { ...file, status: 'REJECTED' }
                  : file
            ))]};
        case FILE_USERNAME:  // Добавляем файлу поле UserName с именем пользователя
              return {...state, files: [...state.files.map(file => (
                  file._id === action.payload.fileid
                    ? { ...file, username : action.payload.username }
                    : file
                ))]};
        case SET_VIEW:            
            return {...state, view: action.payload}
        case SET_FILESCOUNT:            
            return {...state, totalCount: action.payload}
        default:
            return state
    }
}

export const setFiles = (files) => ({type: SET_FILES, payload: files})
export const emptyFiles = () => ({type: EMPTY_FILES})
export const setCurrentDir = (dir) => ({type: SET_CURRENT_DIR, payload: dir})
export const addFile = (file) => ({type: ADD_FILE, payload: file})
export const setPopupDisplay = (display) => ({type: SET_POPUP_DISPLAY, payload: display})
export const pushToStack = (dir) => ({type: PUSH_TO_STACK, payload: dir})
export const popStack = () => ({type: POP_STACK})
export const deleteFileAction = (dirId) => ({type: DELETE_FILE, payload: dirId})
export const sendFileAction = (fileId) => ({type: SEND_FILE, payload: fileId})
export const acceptFileAction = (fileId) => ({type: ACCEPT_FILE, payload: fileId})
export const rejectFileAction = (fileId) => ({type: REJECT_FILE, payload: fileId})
//export const setFileUserName = (fileid, username) => ({type: FILE_USERNAME, payload})
export const setFileView = (payload) => ({type: SET_VIEW, payload})
export const setFilesCount = (payload) => ({type: SET_FILESCOUNT, payload})

