import axios from 'axios'
import { hidePreloader, showPreloader } from '../reducers/appReducer';
import { acceptFileAction, addFile, deleteFileAction, emptyFiles, rejectFileAction, sendFileAction, setFiles, setFilesCount } from '../reducers/fileReducer';
import { addUploadFile, changeUploadFile, showUploader } from '../reducers/uploadReducer';

import {API_URL} from "../config";
import { Navigate } from 'react-router-dom';

export function getFiles(dirId, sort, page, limit) {

    return async dispatch => {
        try {
            dispatch(showPreloader())
            let url = `${API_URL}api/files`
            if(dirId){
                url = `${API_URL}api/files?parent=${dirId}`
            }
            if(sort){
                url = `${API_URL}api/files?sort=${sort}`
            }
            if(dirId && sort){
                url = `${API_URL}api/files?parent=${dirId}&sort=${sort}`
            }
            const response = await axios.get(url, {
                params: {page : page, limit : limit}, 
                headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}
            })

            //console.log(Object.keys(response.data).length);
            
            //console.log(response);
            //console.log(response.headers.get('page-size'));

            //const response = await axios.get(url, {params: {_limit: limit,
            //    _page: page},
            //headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}

            //console.log(response.data)
            dispatch(setFiles(response.data))
            
            //dispatch(setFilesCount(55))
            
        } catch (e) {
            console.log(e?.response?.data?.message)            
        } finally {
            dispatch(hidePreloader())
        }
    }
}

export function getCount() {

    return async dispatch => {    
        try{
            let url = `${API_URL}api/files/count`
            
            const response = await axios.get(url, {                
                headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}
            });
            
            dispatch(setFilesCount(response.data['totalCount']))
        }
        catch (e) {
            console.log(e?.response?.data?.message)            
        } finally {
            dispatch(hidePreloader())
        }        

    }
}

export function getFilesCount() {

    return async dispatch => {
        try {
            //dispatch(showPreloader())
            let url = `${API_URL}api/files/count`
            
            const response = await axios.get(url, {                
                headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}
            });
            
            dispatch(setFilesCount(response.data['totalCount']))
           
        } catch (e) {
            console.log(e?.response?.data?.message)            
        } finally {
            //dispatch(hidePreloader())            
        }
    }
}

export function getAllFilesCount() {

    return async dispatch => {
        try {            
            let url = `${API_URL}api/files/allcount`
            
            const response = await axios.get(url,  {                                
                headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}
            });
            
            dispatch(setFilesCount(response.data['totalCount']))           
        } catch (e) {
            console.log(e?.response?.data?.message)            
        }
    }
}



export function getAllFiles(userId, sort, page, limit) {    

    return async dispatch => {
        
        try {
            dispatch(showPreloader())            
            dispatch(emptyFiles())    
            let url = `${API_URL}api/files/all`

            if(userId){
                url = `${API_URL}api/files/all?user=${userId}`
            }
            if(sort){
                url = `${API_URL}api/files/all?sort=${sort}`
            }
            if(userId && sort){
                url = `${API_URL}api/files/all?user=${userId}&sort=${sort}`
            }
            const response = await axios.get(url, {
                params: {page : page, limit : limit},
                headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}
            })

            

            //console.log(response.data)

            let allfiles = response.data;

            Promise.all(allfiles.map(async function(file){                
                    

                    try {    
                        const response = await axios.get(`${API_URL}api/files/username?userid=${file.user}`, 
                        {headers:{Authorization:`Bearer ${localStorage.getItem('token')}`}}
                        )

                        //file.username = response.data.username

                        //console.log(file._id, response.data.username);

                        //dispatch(setFileUserName(file._id, response.data.username))

                        const newFile = { ...file, username: response.data.username }
                        //console.log(newFile);            
                        return newFile; 

                        //console.log(response.data.username);            
                        //return file
                        
                        
                        
                    } catch (e) {
                        //alert("Тут" + e)        
                        //localStorage.removeItem('token')
                        //alert(e)
                        console.log("An error while getting username from DB");
                    }      
                        

                        //dispatch(setFiles(allfiles))
                    }                
            )
            ).then(newfiles => {                
                //выводим отфильтрованный список файлов (без new)
                dispatch(setFiles(newfiles))                
                //прячем прелоадер (все файлы "загружены")
                dispatch(hidePreloader())
              })            
            
        } catch (e) {
            console.log(e?.response?.data?.message) 
            if(e?.response?.data?.message==="Auth error"){
                // если ошибка авторизации - перекидываем на страницу авторизации
                <Navigate to="/login" />
            }
        } finally {
           
        }
    }
}

export function createDir(dirId, name) {

    return async dispatch => {
        try {
            const response = await axios.post(`${API_URL}api/files`, {
                name,
                type: 'dir',
                parent: dirId
            }, {
            headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}
            })

            //console.log(response.data)
            dispatch(addFile(response.data))
            
        } catch (e) {
            alert(e.response.data.message)
            console.log(e.response.data.message)

        }
    }
}

export function uploadFile(file, dirId) {
    return async dispatch => {
        try {
            
            const formData = new FormData()
            formData.append("file", file)
            formData.append("filename", file.name)
            
            if(dirId){
                formData.append('parent', dirId)
            }

            // Проверить возможно такой файл уже загруже ?! И не добавлять его снова и не выводить Прогресс загрузки в окне.

            const uploadFile = {name:file.name, progress: 0, id: Date.now()}
            dispatch(showUploader())
            dispatch(addUploadFile(uploadFile))


            // for (var pair of formData.entries()) {
            //     console.log(pair[0] + ', ' + pair[1]+ ', ' + pair[2]+ ', ' + pair[3]); 
            // }
            //console.log(formData.entries[0]);
            

            const response = await axios.post(`${API_URL}api/files/upload`, formData, {
                headers: {'Content-Type': 'multipart/form-data;charset=UTF-8',
                    Authorization: `Bearer ${localStorage.getItem('token')}`},
                onUploadProgress: progressEvent => {
                    const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                    //console.log('total', totalLength+"bites")
                    if(totalLength){
                        //let progress = Math.round((progressEvent.loaded * 100) / totalLength)
                        
                        uploadFile.progress=Math.round((progressEvent.loaded * 100) / totalLength)
                        dispatch(changeUploadFile(uploadFile))
                    }
                }
            })
            //console.log(response.data)
            dispatch(addFile(response.data))
            
        } catch (e) {
            console.log(e?.response?.data?.message)            
        }
    }
}

export async function downloadFile(file){
    const response = await fetch(`${API_URL}api/files/download?id=${file._id}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
    if(response.status === 200){
        const blob = await response.blob()
        const downloadUrl = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href=downloadUrl
        link.download = file.name
        document.body.appendChild(link)
        link.click()
        link.remove()
    }
}

export function deleteFile(file) {
    return async dispatch => {
        if(window.confirm("Вы уверены, что хотите удалить файл?")){
            try {
                const response = await axios.delete(`${API_URL}api/files?id=${file._id}`,{
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                })
                
                //dispatch(deleteFileAction(file._id))
                //alert(response.data.message)

                if(response.status===200){
                    dispatch(deleteFileAction(file._id))                                
                }
            } catch (e) {
                console.log(e.response.data.message)            
            }
        }
    }
}

export function sendFile (file) {
    return async dispatch => {
        try {
            const params = {file}
            const response = await axios.put(`${API_URL}api/files/send?id=${file._id}`, params, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })    

            //console.log(response.data);
            //dispatch(sendFileAction(file._id))
            
            if(response.status===200){
                alert("Файл успешно отправлен на проверку!")                                
            }

            // if(window.confirm("Вы уверены, что хотите удалить?")){
            //     dispatch(deleteFileAction(file._id))
            // }            
        } catch (e) {
            console.log(e)            
            console.log(e.response)            
        } finally{
            dispatch(sendFileAction(file._id))                
        }
    }
}

export function acceptFile (file) {
    return async dispatch => {
        try {
            const params = {file}
            //alert(file._id);
            const response = await axios.put(`${API_URL}api/files/accept?id=${file._id}`, params, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })    

            //console.log(response.data);
            //dispatch(sendFileAction(file._id))
            
            if(response.status===200){
                alert("Статус файла изменен (принят)!")                                
            }
           
        } catch (e) {
            console.log(e)                        
        } finally{
            dispatch(acceptFileAction(file._id))                
        }
    }
}

export function rejectFile (file) {
    return async dispatch => {
        try {
            const params = {file}
            const response = await axios.put(`${API_URL}api/files/reject?id=${file._id}`, params, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })    

            //console.log(response.data);
            //dispatch(sendFileAction(file._id))
            
            if(response.status===200){
                alert("Статус файла изменен (отклонён)!")                                
            }
           
        } catch (e) {
            console.log(e)                        
        } finally{
            dispatch(rejectFileAction(file._id))                
        }
    }
}

export function searchFiles(search) {
    return async dispatch => {
        try {
            const response = await axios.get(`${API_URL}api/files/search?search=${search}`,{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
            
            dispatch(setFiles(response.data))
            //alert(response.data.message)
            
        } catch (e) {
            console.log(e.response.data.message)            
        } finally {
            dispatch(hidePreloader())
        }
    }
}
