import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllFiles, getAllFilesCount, getFiles, searchFiles } from '../../actions/file';
import { showPreloader } from '../../reducers/appReducer';
import { emptyFiles, setFiles, setFileView } from '../../reducers/fileReducer';
import { getEnding, getPagesCount } from '../../utils/page';
import FileList from '../manager/fileList/FileList';
import Pagination from '../UI/pagination/Pagination';
import './manager.css';



const Manager = () => {
    const dispatch = useDispatch()
    const currentDir = useSelector(state => state.files.currentDir)
    const totalCount = useSelector(state => state.files.totalCount)
    const loader = useSelector(state => state.app.loader)
    
    const [sort, setSort] = useState('date')

    const [searchName, setSearchName] = useState('')
    const [searchTimeout, setSearchTimeout] = useState(false)

    const [totalPages, setTotalPages] = useState(0);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);

    const isAuth = useSelector(state => state.user.isAuth)      

    //const userId = "6321db073bb079c9e2368859"
    
    useEffect(() =>{                
        //dispatch(getFiles(currentDir, sort))

        dispatch(getAllFilesCount());        
        setTotalPages(getPagesCount(totalCount, limit)); 
        dispatch(getAllFiles(111, sort, page, limit));
    }, [sort])

    
    function searchChangeHandler(e){
        setSearchName(e.target.value)  
        //console.log(e.target.value.toLowerCase());
        if(searchTimeout!==false){
          clearTimeout(searchTimeout)
        }
        //dispatch(showPreloader())
        if(e.target.value!=="")
        {
          setSearchTimeout(setTimeout((value)=>{
            
            dispatch(searchFiles(value.toLowerCase()))
          }, 50, e.target.value))  
          
        } else {
          dispatch(getAllFiles(sort))
        }           
    }

    const changePage = (page) => {
        setPage(page);     
        //alert(page);
        //alert(limit);
        dispatch(getAllFiles(111, sort, page, limit));     
    }
    

    if(loader === true){
        return (
            <div className="loader">
               <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
        )
    }
    
    return (
        <div className="disk">
            <div className="disk__btns">
                <div className="disk__upload">
                    
                </div>
                <div className='disk__search'>
                {isAuth && <div className='navbar__searchbar'>
                Поиск: <input 
                value={searchName} 
                onChange={e => searchChangeHandler(e)} 
                className='navbar__search' 
                type="text" 
                placeholder="Название файла..."/></div>}

                </div>
                <div className='disk__sort'>Сортировать:  
                    <select 
                        className="disk__select" 
                        value={sort} 
                        onChange={(e) => setSort(e.target.value)}>
                        <option value="date">По дате</option>
                        <option value="status">По статусу</option>
                        <option value="name">По имени</option>                        
                        <option value="type">По типу</option>
                    </select>
                </div>
                <div className='disk__view'>
                    <button onClick={() => dispatch(setFileView('plate'))} className="disk__plate"></button> 
                    <button onClick={() => dispatch(setFileView('list'))} className="disk__list"></button>
                </div>
            </div>
            <FileList />  
            Всего в системе: {totalCount} {getEnding(totalCount, ['файл', 'файла', 'файлов'])}
            
            {/* здесь добавляем компонент Пагинация Pagination (который выводит страницы со ссылками) */}
            {/* Страницы: {totalCount/5}             */}
            <Pagination 
                page={page}                 
                changePage={changePage} 
                totalPages={totalPages} 
            />          
        </div>
    )
}

export default Manager;